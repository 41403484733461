import { FC, useEffect, useRef } from "react";
import "./ourStory.css";
import React from "react";
import useIntersectionObserver from "../../../../services/intersectionObserver";

const OurStory: FC = () => {
  const [divKey, setDivKey] = React.useState<number>(0);
  const animationTriggered = useRef(false);
  const divRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersectionObserver(divRef, { threshold: 0.1 });

  useEffect(() => {
    if (isIntersecting && !animationTriggered.current) {
      setDivKey((prevKey) => prevKey + 1);
      animationTriggered.current = true;
    }
  }, [isIntersecting, divKey]);

  return (
    <div id="our-story-section" className="our-story landing-page-section">
      <div key={divKey} ref={divRef} className="centered-our-story-section">
        <h1>ABOUT US</h1>
        <p>
          We believe the breathtaking beauty of the UAE's diverse landscapes
          should be accessible to all. That’s why we created Darb—the ultimate
          hiking app that redefines outdoor adventures in the UAE. Our mission
          is to provide a safe, user-friendly, and comprehensive platform that
          caters to the unique needs of the local community while inspiring
          exploration and connection with nature
        </p>
        <br />
        <p>
          Born from a shared passion for adventure and deep appreciation for the
          natural wonders, Darb is your trusted companion. Whether you're a
          seasoned trekker or a newcomer to the trails, Darb is here to guide
          you through unforgettable journeys across awe-inspiring landscapes
        </p>
      </div>
    </div>
  );
};

export default OurStory;
