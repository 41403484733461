import React, { FC, useEffect, useRef } from 'react';
import './homeSection.css';
import Navbar from '../../../navBar/Navbar';
import useIntersectionObserver from '../../../../services/intersectionObserver';

const Home: FC = () => {
    // const [userInput, setUserInput] = React.useState<string>('');
    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef);

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
          }
    }
    , [isIntersecting, divKey]);
    
    return (
        <div id="home-section" className="home landing-page-section">
            <Navbar />
            <div key={divKey} ref={divRef} className="centered-home-section">
                <div className="left-side-home-section">
                    <h1>
                        WELCOME TO DARB!
                    </h1>
                    <h2>
                        EXPLORE. EXPERIENCE. ESCAPE.
                    </h2>
                    <p>
                     A Mobile Application designed for outdoor enthusiasts of all levels! Darb offers a seamless way to discover and
                     navigate trails across the UAE. Whether you’re exploring
                     based on distance, difficulty, or duration, Darb provides
                     verified trail information and real-time navigation.
                     Tailored to enhance every adventure, it empowers users to
                     explore confidently while promoting responsible and eco-
                     conscious outdoor activities                   </p>
                  
                </div>
                <div className="right-side-home-section">
                    <img src={require('../../../../Assets/homeLogo.png')} alt="Darb Logo" width={'350px'}/>
                </div>
            </div>
        </div>
    );
}

export default Home;