import React, { FC, useEffect, useRef } from 'react';
import './getStartedNowSection.css';
import useIntersectionObserver from '../../../../services/intersectionObserver';

const GetStartedNow: FC = () => {

    // const [userInput, setUserInput] = React.useState<string>('');
    const [divKey, setDivKey] = React.useState<number>(0);
    const animationTriggered = useRef(false);
    const divRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(divRef);

    useEffect(() => {
        if (isIntersecting && !animationTriggered.current) {
            setDivKey(prevKey => prevKey + 1);
            animationTriggered.current = true;
        }
    }
        , [isIntersecting, divKey]);

    return (
        <div id="get-started-now-section" className="get-started-now landing-page-section">
            <div key={divKey} ref={divRef} className="centered-get-started-now-section">
                <div className="left-side-get-started-now-section">
                    <h1>
                        Download Now!
                    </h1>
                    <h2>
                        Available on Google Play and App Store
                    </h2>
                    <p>
                        Embark on a safe outdoor adventure with Darb as your guide. Explore the beauty of the MENA Region's nature while ensuring a secure and delightful experience.                    </p>
                    <div className="left-side-get-started-now-section-cta">
                        <a href="https://play.google.com/" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Download on Google Play"
                                style={{ height: '70px', marginRight: '10px' }}
                            />
                        </a>

                        {/* App Store Badge */}
                        <a href="https://play.google.com/" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                                alt="Download on Google Play"
                                style={{ height: '70px', marginRight: '10px' }}
                            />
                        </a>
                    </div>
                </div>
                <div className="right-side-get-started-now-section">
                    <img src={require('../../../../Assets/getStartedNowMockup.png')} alt="Darb Mockups" width={'350px'} />
                </div>
            </div>
        </div>
    );
}

export default GetStartedNow;