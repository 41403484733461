import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="footer">
      <p className="CompanyName">Darb @ 2024. All rights reserved.</p>
      <ul>
        <a
          href="https://www.facebook.com/share/8aQao4Rcw6b4hk5U/?mibextid=LQQJ4d"
          className="facebook"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://www.instagram.com/darb.app/"
          className="instagram"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </ul>
    </footer>
  );
}
